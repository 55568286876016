define(['app', 'accessibleModalHelper'], function (app, accessibleModalHelper) {

  const wishlistsNewsletterModal = () => {
    const component = {};

    const eventCategoryTraking = 'Wishlist Engagement';

    const _config = {
      wishlistsNewsletterModalContainer: '.wishlistsNewsletterModal_container',
      wishlistsNewsletterModalTitle: '.wishlistsNewsletterModal_title',
      wishlistsNewsletterModalOptInButton: '[data-js-element=wishlists-newsletter-modal-button]',
      wishlistsNewsletterModalSuccessfulButton: '[data-js-element=wishlists-newsletter-modal-successful-button]',
      wishlistsNewsletterModalCloseButton: '.wishlistsNewsletterModal_close',
      wishlistsNewsletterModalRetryButton: '[data-js-element=wishlists-newsletter-modal-retry-button]',
      wishlistsNewsletterModalLaterButton: '[data-js-element=wishlists-newsletter-modal-later-button]',
      cookieName: 'wishlistsNewsletterModalCookie',
      noScrollClass: 'wishlistsNewsletterModal_noscroll',
      hiddenClass: 'wishlistsNewsletterModal-hide',
      showClass: 'wishlistsNewsletterModal-show',
    };

    const _sections = {
      formSection: '.wishlistsNewsletterModal_form_section',
      succesfullSection: '.wishlistsNewsletterModal_successful_section',
      failureSection: '.wishlistsNewsletterModal_failure_section',
    };

    const _init = (element) => {
      component.element = element;
      component.elementContainer =
        component.element.querySelector(component.config.wishlistsNewsletterModalContainer);

      component.submitButton = component.element.querySelector(component.config.wishlistsNewsletterModalOptInButton);
      component.successButton = component.element.querySelector(component.config.wishlistsNewsletterModalSuccessfulButton);
      component.closeButton = component.element.querySelector(component.config.wishlistsNewsletterModalCloseButton);
      component.retryButton = component.element.querySelector(component.config.wishlistsNewsletterModalRetryButton);
      component.laterButton = component.element.querySelector(component.config.wishlistsNewsletterModalLaterButton);
      component.title = component.element.querySelector(component.config.wishlistsNewsletterModalTitle);

      component.formSection = component.element.querySelector(component.sections.formSection);
      component.succesfullSection = component.element.querySelector(component.sections.succesfullSection);
      component.failureSection = component.element.querySelector(component.sections.failureSection);

      component.csrfToken = component.element.querySelector("[name=csrf_token]").value;

      if (!component.checkCookieExists(component.config.cookieName)) {
        component.showWishlistNewsletterModal();
      }

      return component;
    };

    const _showWishlistNewsletterModal = () => {
      component.submitButton.addEventListener('click', component.submitNewsletterOptIn);
      component.successButton.addEventListener('click', component.closeModalAndPlaceCookie);
      component.closeButton.addEventListener('click', component.closeWishlistNewsletterModal);
      component.retryButton.addEventListener('click', component.retryHandler);
      component.laterButton.addEventListener('click', component.laterHandler);

      component.element.classList.remove(component.config.hiddenClass);
      component.element.classList.add(component.config.showClass);

      document.body.classList.add(component.config.noScrollClass);

      component.accessibleModalHelper = new accessibleModalHelper(component.element, component.closeWishlistNewsletterModal, component.title);

      app.publish('tracking/record', eventCategoryTraking, 'Wishlists Newsletter Modal | Shown', 'Wishlists Newsletter Form');
    };

    const _closeModalAndPlaceCookie = () => {
      component.element.classList.remove(component.config.showClass);
      component.element.classList.add(component.config.hiddenClass);

      document.body.classList.remove(component.config.noScrollClass);

      component.accessibleModalHelper.close();

      component.setCookie(component.config.cookieName, 'wishlistsNewsletterModalShown');
    };

    const _retryHandler = () => {
      component.failureSection.classList.remove(component.config.showClass);
      component.failureSection.classList.add(component.config.hiddenClass);

      component.formSection.classList.remove(component.config.hiddenClass);
      component.formSection.classList.add(component.config.showClass);
    };

    const _laterHandler = () => {
      component.element.classList.remove(component.config.showClass);
      component.element.classList.add(component.config.hiddenClass);
    };

    const _closeWishlistNewsletterModal = () => {
      app.publish('tracking/record', eventCategoryTraking, 'Wishlists Newsletter Modal | Close');
      component.closeModalAndPlaceCookie();
    };

    const _submitNewsletterOptIn = () => {

      app.publish('tracking/record', eventCategoryTraking, 'Wishlists Newsletter Modal | Clicked yes notify me button');

      component.postNewsletterPreference()
        .then(component.successHandler)
        .catch(component.errorHandler);
    };



    const _postNewsletterPreference = () => {
      return new Promise((res, rej) => app.ajax.post({
        url: '/wishlistEmailPreferences.account?receivedNewsletter=true',
        send: 'csrf_token=' + component.csrfToken,
        requestHeader: {
          header: 'Content-Type',
          value: 'application/x-www-form-urlencoded',
        },
        success: res,
        error: rej,
      }));
    };

    const _errorHandler = () => {
      console.error(
        'ERROR: Could not process the newsletter preferences');
      component.formSection.classList.remove(component.config.showClass);
      component.formSection.classList.add(component.config.hiddenClass);

      component.failureSection.classList.remove(component.config.hiddenClass);
      component.failureSection.classList.add(component.config.showClass);
    };

    const _successHandler = () => {
      component.formSection.classList.remove(component.config.showClass);
      component.formSection.classList.add(component.config.hiddenClass);

      component.succesfullSection.classList.remove(component.config.hiddenClass);
      component.succesfullSection.classList.add(component.config.showClass);
    };

    // set cookie name,value and expiration date
    const _setCookie = (name, value) => {
      let path = '/'; // it has to be root for the cookie to be visible on the entire website
      let expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 5);

      document.cookie = name + '=' + value + '; expires=' + expirationDate.toUTCString() + '; path=' + path;
    };

    // check if the cookie exists in the user's browser
    const _checkCookieExists = (name) => {
      const cookieName = name + '=';
      const ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(cookieName) == 0) return true;
      }
      return false;
    };

    component.config = _config;
    component.sections = _sections;
    component.init = _init;
    component.submitNewsletterOptIn = _submitNewsletterOptIn;
    component.postNewsletterPreference = _postNewsletterPreference;
    component.errorHandler = _errorHandler;
    component.successHandler = _successHandler;
    component.setCookie = _setCookie;
    component.checkCookieExists = _checkCookieExists;
    component.showWishlistNewsletterModal = _showWishlistNewsletterModal;
    component.closeModalAndPlaceCookie = _closeModalAndPlaceCookie;
    component.closeWishlistNewsletterModal = _closeWishlistNewsletterModal;
    component.retryHandler = _retryHandler;
    component.laterHandler = _laterHandler;

    return component;
  };

  return wishlistsNewsletterModal;
});
